<template>
  <div class="dataProceslog-container">
    <div class="dataProceslog-container-main">
      <div class="dataProceslog-bar">
        <span class="control-left">
          <span>{{ $t('navbar.dataProcessinglog') }}</span>
          <span class="control-text">
            <span>{{ $t('activities.ProcessingTimes') }} : </span>
            <span>{{ formatToLocaleString(processingTimes) }}</span>
          </span>
        </span>
      </div>
      <div class="dataProceslog-list">
        <el-table
          v-loading="listLoading"
          stripe
          :data="tableData"
        >
          <el-table-column
            v-for="(item, index) in field"
            :key="index"
            :label="$t(`activities.${item.prop}`)"
            :prop="item.prop"
            :width="item.width"
          />
          <template slot="empty">
            <el-empty :description="$t('general.NoData')" />
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getSdvProcessLog } from '@/api/activities'
import { UTCtoLocalTime } from '@/datetime/index'
import { FormatNumber } from '@/Mixins'

export default {
  name: 'DataProcessinglog',
  mixins: [FormatNumber],
  data () {
    return {
      listLoading: false,
      tableData: [],
      field: [
        {
          prop: 'created_at',
          width: 250
        },
        {
          prop: 'log'
        },
        {
          prop: 'proces_user',
          width: 150
        }
      ],
      processingTimes: 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const res = await getSdvProcessLog()
      this.processingTimes = res.data.total_num
      this.tableData = res.data.logs.map((item) => {
        const main_type = this.$t(`general.${item.main_type}`)
        const type = this.$t(`mainType.${item.type}`)
        let subtype = ''
        if (item.subtype) {
          subtype = `-${this.$t(`subType.${item.subtype}`)} `
        }
        const newObj = {
          ...item,
          log: `處理 ${main_type}-${type}${subtype} 共 ${this.formatToLocaleString(item.total_num)}次, 產生${this.formatToLocaleString(item.output_num)}筆資料`,
          created_at: UTCtoLocalTime(item.created_at),
          proces_user: '系統管理員'
        }
        return newObj
      })
      this.listLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.dataProceslog-container {
  .dataProceslog-container-main {
    position: relative;
    flex-direction: column;
    .el-table .el-button {
      border: none;
      background-color: transparent;
    }
  }
}

.dataProceslog-bar {
  display:flex;
  margin-bottom: 10px;
  .control-text{
    margin-left: 10px;
    font-size: 16px;
  }
}

/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
